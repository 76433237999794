import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const AboutPage = () => (
  <Layout>
    <Seo title="О Нас" pathname='o-nas' />
    <div className="article underline">
      <section id="about">
        <p>
          Служение <strong>Мир с Богом</strong> создано и поддерживается{' '}
          <strong>Евангелистской Ассоциацей Билли Грэма (ЕАБГ)</strong> в
          партнерстве с Jesus.net.
        </p>
        <p>
          ЕАБГ поддерживает и расширяет евангельское служение Билли и Франклина
          Грэмов, провозглашая Евангелие Иисуса Христа всеми доступными
          эффективными средствами, а также предоставляет необходимое обучение и
          ресурсы другим для аналогичного служения. Jesus.net — это
          международная сеть состоящая из более чем 40 партнеров, которые
          объединяются, чтобы воспитывать последователей Христа с помощью
          инновационных и творческих интернет-сайтов и онлайн-инструментов.
          Видение нашего служения состоит в том, чтобы:
        </p>
        <ol>
          <li>
            <strong>Содействовать</strong> духовному пути людей, ищущих жизнь с
            избытком.
          </li>
          <li>
            <strong>Воодушевлять</strong> свою аудиторию на шаги, ведущие к
            ощущению силы общности и общения в теле Христовом.
          </li>
          <li>
            <strong>Стимулировать</strong> творческий потенциал своих партнеров
            по всему миру.
          </li>
        </ol>
        <p>
          <strong>Мир с Богом</strong> управляет веб-сайтами и страницами в
          социальных сетях на других языках, включая:{' '}
          <a
            href="https://peacewithgod.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Peace With God
          </a>
          ,{' '}
          <a
            href="https://salammaaallah.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Salam Maa Allah
          </a>
          ,{' '}
          <a
            href="https://pazcondios.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Paz Con Dios
          </a>{' '}
          и{' '}
          <a
            href="https://pazcomdeus.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Paz Com Deus
          </a>
          .
        </p>
        <hr />
        <p>Адрес:</p>
        <p>
          Billy Graham Evangelistic Association <br />1 Billy Graham Parkway
          <br />
          Charlotte, NC 28201, USA
        </p>
        <p>
          Телефон: <a href="tel:+1-704-401-2432">+1 (704) 401-2432</a>
        </p>
        <p>
          Электронная почта:{' '}
          <a href="mailto:mirsbogom@billygraham.org">
            mirsbogom@billygraham.org
          </a>
        </p>
        <ul>
          <li>
            <a href="/o-nas/">О Нас</a>
          </li>
          <li>
            <a
              href="https://billygraham.org/politika-konfidentsialnost/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Конфиденциальность
            </a>
          </li>
          <li>
            <a href="/avtorskiye-prava/">Авторское право</a>
          </li>
        </ul>
      </section>
    </div>
  </Layout>
);

export default AboutPage;
